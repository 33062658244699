import { useCallback, useContext } from 'react';

import { Organization } from '@/types';

import { AppSettingContext } from './context';
import { AdminOrg, Auth, Claim, Theme } from './reducer';

export function useAppSetting() {
  const [{ theme, i18n, auth, claim, adminOrg, joinedOrg }, dispatch] =
    useContext(AppSettingContext);

  const setTheme = useCallback(
    (theme: Theme) => dispatch('setTheme', theme),
    []
  );
  const setAuth = useCallback((auth: Auth) => dispatch('setAuth', auth), []);
  const setClaim = useCallback(
    (claim: Claim) => dispatch('setClaim', claim),
    []
  );
  const setAdminOrg = useCallback(
    (adminOrg: AdminOrg[]) => dispatch('setAdminOrg', adminOrg),
    []
  );
  const setJoinedOrg = useCallback(
    (joinedOrg: Organization[]) => dispatch('setJoinedOrg', joinedOrg),
    []
  );

  return {
    appSetting: {
      theme,
      i18n,
      auth,
      claim,
      adminOrg
    },
    theme,
    i18n,
    auth,
    claim,
    adminOrg,
    joinedOrg,
    setTheme,
    setAuth,
    setClaim,
    setAdminOrg,
    setJoinedOrg
  };
}
