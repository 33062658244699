import { NavigateFunction } from 'react-router-dom';
import jazzicon from '@metamask/jazzicon';
import * as htmlToImage from 'html-to-image';

import { apiGetSig, apiLogin } from '@/apis/login';

import { getWeb3Account, getWeb3SignMessage } from './web3';

export const linkAfterCheckLogin = async (
  route: string,
  navigate: NavigateFunction,
  beforeLoad?: () => void
) => {
  if (!localStorage.getItem('token') || !localStorage.getItem('address')) {
    if (beforeLoad) beforeLoad();
    const res: any = await utilLogin();
    if (res.auth.token) {
      if (route == '/profile') {
        navigate(`/user/${res.auth.address}`, { replace: true });
      } else {
        navigate(route, { replace: true });
      }
      window.location.reload();
    }
  } else {
    if (beforeLoad) beforeLoad();
    if (route == '/profile') {
      navigate(`/user/${localStorage.getItem('address')}`, { replace: true });
    } else {
      navigate(route, { replace: true });
    }
  }
};

export const utilLogin = async () => {
  const adds = await getWeb3Account();
  const msg = await apiGetSig(adds[0]);
  if (!msg) return;
  const sig = await getWeb3SignMessage(msg);
  if (!sig) return;
  const avatar = jazzicon(64, parseInt(adds.slice(2, 8), 16));
  document.body.appendChild(avatar);
  const dataUrl = await htmlToImage.toPng(avatar as any);
  document.body.removeChild(avatar);
  const res: any = await apiLogin(adds[0], sig, dataUrl.split(';base64,')[1]);
  if (res.token) {
    localStorage.setItem('isInWhiteList', res.isInWhiteList);
    localStorage.setItem('token', res.token);
    localStorage.setItem('address', res.user.address);
    localStorage.setItem('name', res.user.name);
    localStorage.setItem('avatar', res.user.avatar);
    localStorage.setItem('uid', res.user.id);
    localStorage.setItem('number', res.user.number);
    localStorage.setItem('popup', res.user.popup);
    localStorage.setItem('hasNewNotice', res.user.hasNewNotice);
    return {
      auth: {
        address: res.user.address,
        token: res.token,
        name: res.user.name,
        avatar: res.user.avatar,
        uid: res.user.id,
        isInWhiteList: res.isInWhiteList,
        number: res.user.number,
        popup: res.user.popup,
        hasNewNotice: res.user.hasNewNotice
      },
      data: res
    };
  }
  return false;
};

export const checkoutToken = () => {
  try {
    const token = localStorage.getItem('token') || '';
    if (token) {
      const exp = window.atob(token.split('.')[1]);
      if (new Date().getTime() > +exp * 1000) {
        // 过期
        loginOut();
        return false;
      } else {
        return true;
      }
    }
    return false;
  } catch (error) {
    loginOut();
    return false;
  }
};

export const loginOut = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('address');
  localStorage.removeItem('name');
  localStorage.removeItem('avatar');
  localStorage.removeItem('uid');
  localStorage.removeItem('isInWhiteList');
  localStorage.removeItem('number');
  localStorage.removeItem('popup');
  localStorage.removeItem('hasNewNotice');
};
