import React from 'react';
import ReactDOM from 'react-dom/client';
import { NextUIProvider } from '@nextui-org/react';

import flexible from '@/utils/flexible';

import App from './App';
import sentry from './utils/sentry';

import '@/styles/global.css';
import '@/styles/tailwindcss.css';

if (window.matchMedia('(prefers-color-scheme: dark)').matches)
  document.documentElement.classList.add('dark');
else document.documentElement.classList.add('light');

flexible(window, document);

sentry();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <NextUIProvider>
      <App />
    </NextUIProvider>
  </React.StrictMode>
);
