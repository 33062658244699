import toast from '@/components/toast';
import request from '@/utils/request';

/**
 * @description GET Org List
 * @params address 地址
 */
export const apiGetOrgList = async (data: {
  orgIdList?: string;
  isAdmin?: boolean;
  userAddress?: string;
  slugList?: string;
  contractAddressList?: string;
}) => {
  const res: any = await request(
    `/api/v1/org?isAdmin=${data.isAdmin ? 'true' : 'false'}${
      data.orgIdList ? `&orgIdList=${data.orgIdList}` : ''
    }${data.userAddress ? `&userAddress=${data.userAddress}` : ''}${
      data.slugList ? `&slugList=${data.slugList}` : ''
    }${
      data.contractAddressList
        ? `&contractAddressList=${data.contractAddressList}`
        : ''
    }`
  );
  if (res.code === 0) {
    return res.data;
  } else {
    if (+res.code === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('address');
      window.location.href = '/home';
    }
    toast.error(`${res.msg}`, { id: `${res.msg}` });
    return '';
  }
};

/**
 * @description org 创建
 * @params data
 */
export const apiCreateOrg = async (data: {
  adminAddressList: string[];
  Avatar: string;
  avatar: string;
  slug: string;
  desc: string;
  discord: string;
  name: string;
  twitter: {
    name: string;
    requestTime?: any;
    sig?: string;
  };
  bannerImage: string;
  email: string;
  website: string;
  debox: string;
}) => {
  const res: any = await request(`/api/v1/org`, {
    method: 'POST',
    body: data
  });

  if (res.code === 0) {
    return res.data;
  } else {
    if (+res.code === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('address');
      window.location.href = '/home';
    }
    toast.error(`${res.msg}`, { id: `${res.msg}` });
    return '';
  }
};

/**
 * @description org update
 * @params data
 */
export const apiUpdateOrg = async (data: {
  adminAddressList: string[];
  Avatar: string;
  avatar: string;
  desc: string;
  discord: string;
  name: string;
  twitter: {
    name: string;
    requestTime: any;
    sig: string;
  };
  id?: string;
  bannerImage: string;
  email: string;
  website: string;
  debox: string;
}) => {
  const res: any = await request(`/api/v1/org`, {
    method: 'PUT',
    body: data
  });

  if (res.code === 0) {
    return res.data;
  } else {
    if (+res.code === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('address');
      window.location.href = '/home';
    }
    toast.error(`${res.msg}`, { id: `${res.msg}` });
    return '';
  }
};

/**
 * @description GET Org List
 * @params address 地址
 */
export const apiGetSlug = async (slug) => {
  const res: any = await request(`/api/v1/org/slug-check?slug=${slug}`);
  if (res.code === 0) {
    return res.data;
  } else {
    if (+res.code === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('address');
      window.location.href = '/home';
    }
    toast.error(`${res.msg}`, { id: `${res.msg}` });
    return '';
  }
};
/**
 * @description bind snapshot space
 * @params address 地址
 */
export const apiBindSpace = async (data: {
  orgID: string;
  spaceEns: string;
}) => {
  try {
    const res: any = await request(`/api/v1/org/bind`, {
      method: 'POST',
      body: data
    });
    if (res.code === 0) {
      return res.data;
    } else {
      throw new Error(res.message ?? res.msg);
    }
  } catch (err: any) {
    toast.error(`${err.message ?? err.msg}`);
    throw err;
  }
};

export const leave = async (data: { orgID: string }) => {
  try {
    const res: any = await request(`/api/v1/org/leave`, {
      method: 'POST',
      body: data
    });
    if (res.code === 0) {
      return res.data;
    } else {
      throw new Error(res.message ?? res.msg);
    }
  } catch (err: any) {
    toast.error(`${err.message ?? err.msg}`);
    throw err;
  }
};
export const join = async (data: { orgID: string }) => {
  try {
    const res: any = await request(`/api/v1/org/join`, {
      method: 'POST',
      body: data
    });
    if (res.code === 0) {
      return res.data;
    } else {
      throw new Error(res.message ?? res.msg);
    }
  } catch (err: any) {
    toast.error(`${err.message ?? err.msg}`);
    throw err;
  }
};
