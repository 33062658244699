// project imports
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import Layout from '@/components/layout';
import Loadable from '@/components/Loadable';
import Organization from '@/pages/organization';
import OrganizationDetail from '@/pages/organization/detail';
import OrganizationList from '@/pages/organization/list';

const Home = Loadable(lazy(() => import('@/pages/home')));
const Profile = Loadable(lazy(() => import('@/pages/profile')));
const OrgIndex = Loadable(lazy(() => import('@/pages/org')));
const SbtDetailPage = Loadable(lazy(() => import('@/pages/sbt/detail')));
const GitcoinPage = Loadable(lazy(() => import('@/pages/donation/gitcoin')));
const OrgSendPage = Loadable(lazy(() => import('@/pages/org/send')));
const ProfileSetting = Loadable(lazy(() => import('@/pages/profile/setting')));
const ProfileClaim = Loadable(lazy(() => import('@/pages/profile/claim')));
const OrgEdit = Loadable(lazy(() => import('@/pages/org/edit')));
const SbtHistoryPage = Loadable(lazy(() => import('@/pages/sbt/history')));
const SbtEditPage = Loadable(lazy(() => import('@/pages/sbt/edit')));
const ScoreEdit = Loadable(lazy(() => import('@/pages/point/edit')));
const UserBPView = Loadable(lazy(() => import('@/pages/point/user')));
const NotFound = Loadable(lazy(() => import('@/pages/4xx/404')));
const ProposalList = Loadable(lazy(() => import('@/pages/proposal/list')));
const ProposalCreate = Loadable(lazy(() => import('@/pages/proposal/create')));
const ProposalDetail = Loadable(
  lazy(() => import('@/pages/proposal/detail/index'))
);
const OrganizationSettings = Loadable(
  lazy(() => import('@/pages/organization/settings/index'))
);
const OrganizationHome = Loadable(
  lazy(() => import('@/pages/organization/home/index'))
);
const Points = Loadable(lazy(() => import('@/pages/points')));
const PointsDetail = Loadable(
  lazy(() => import('@/pages/points/pointsDetail'))
);
const PointsList = Loadable(lazy(() => import('@/pages/points/pointsList')));

/**
 * 公开路由
 * org/:id
 * user/:address
 * sbt/:contract/:tokenid
 *
 * 私密路由
 * profile/setting
 *
 * manage/org/send
 * manage/org/history
 * manage/org/edit
 */

const MainRoutes = {
  path: '/',
  element: <Layout />,
  children: [
    {
      path: '/',
      element: <Navigate to="/home" />
    },
    {
      path: '/home',
      element: <Home />,
      meta: {
        auth: false
      }
    },
    {
      path: '/organization',
      element: <Organization />, // org layout
      meta: { auth: false },
      children: [
        {
          path: '',
          element: <OrganizationList />, // org list
          meta: { auth: false }
        },
        {
          path: 'new',
          element: <OrganizationSettings />
        },
        {
          path: ':id',
          element: <OrganizationDetail />, // org detail
          meta: { auth: false },
          children: [
            {
              path: '',
              element: <Navigate to="home" replace />
            },
            // home
            {
              path: 'home',
              element: <OrganizationHome />
            },

            {
              path: 'proposal-list',
              element: <ProposalList />
            },
            {
              path: 'proposal-create',
              element: <ProposalCreate />
            },
            {
              path: 'proposal-list/:proposalId',
              element: <ProposalDetail />
            },
            {
              path: 'settings',
              element: <OrganizationSettings />
            },
            {
              path: 'points',
              element: <Points />
            },
            {
              path: 'points-batch-management',
              element: <PointsList />
            }
          ]
        },
        {
          path: 'send-sbt',
          element: <OrgSendPage />,
          meta: { auth: false }
        },
        {
          path: 'send-points',
          element: <PointsDetail />,
          meta: { auth: false }
        }
      ]
    },
    {
      path: '/user/:id',
      element: <Profile />,
      meta: {
        auth: false
      }
    },
    {
      path: '/org/:id',
      element: <OrgIndex />,
      meta: {
        auth: false
      }
    },
    {
      path: '/sbt/:org/:id',
      element: <SbtDetailPage />,
      meta: {
        auth: false
      }
    },
    {
      path: '/point/:contract/:address',
      element: <UserBPView />,
      meta: {
        auth: false
      }
    },
    {
      path: '/donation/gitcoin',
      element: <GitcoinPage />,
      meta: {
        auth: false
      }
    },
    {
      // 个人信息编辑
      path: '/profile/setting',
      element: <ProfileSetting />,
      meta: {
        auth: true
      }
    },
    {
      // 通知mail
      path: '/profile/claim',
      element: <ProfileClaim />,
      meta: {
        auth: true
      }
    },
    {
      // 组织信息编辑和创建
      path: '/manage/org/edit',
      element: <OrgEdit />,
      meta: {
        auth: true
      }
    },
    // {
    //   // sbt发送
    //   path: '/manage/org/send',
    //   element: <OrgSendPage />,
    //   meta: {
    //     auth: true
    //   }
    // },
    {
      // 组织历史总表
      path: '/manage/org/history',
      element: <SbtHistoryPage />,
      meta: {
        auth: true
      }
    },
    {
      // 编辑组织内的 sbt 信息
      path: '/manage/org/sbt/edit',
      element: <SbtEditPage />,
      meta: {
        auth: true
      }
    },
    {
      // 编辑组织内的 score 信息
      path: '/manage/org/point/edit',
      element: <ScoreEdit />,
      meta: {
        auth: true
      }
    },
    {
      // 404
      path: '/404',
      element: <NotFound />,
      meta: {
        auth: false
      }
    },
    {
      // 404
      path: '/*',
      element: <NotFound />,
      meta: {
        auth: false
      }
    }
  ]
};

export default MainRoutes;
