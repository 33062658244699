import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import Loadable from '@/components/Loadable';
import Layout from '@/pages/h5/layout';
const H5Page = Loadable(lazy(() => import('@/pages/h5/index')));

const H5Routes = {
  path: '/h5',
  element: <Layout />,
  children: [
    {
      path: '/h5/',
      element: <Navigate to="/h5/home" />
    },
    {
      path: '/h5/:slug',
      element: <H5Page />
    }
  ]
};

export default H5Routes;
