import { Link } from 'react-router-dom';

export const Organization = (): JSX.Element => {
  return (
    <div className="mr-[24px]">
      <Link to="/organization">
        <p className="cursor-pointer items-center font-[500] text-[#1D2129]">
          Organization
        </p>
      </Link>
    </div>
  );
};
