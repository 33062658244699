import { ProposalListItem, User } from '@/types';
/**
 * @description 获取API URL前缀，根据环境进行判断
 */
export const getBaseUrl = (url: string): string => {
  let BASE_URL = '';
  if (process.env.NODE_ENV === 'development') {
    // 开发环境 - 根据请求不同返回不同的BASE_URL
    if (url.includes('/api/')) {
      BASE_URL = 'https://vitae-api.inft.host';
    } else if (url.includes('/other-api/')) {
      BASE_URL = 'http://test-other-api.vadxq.com';
    } else {
      BASE_URL = 'https://vitae-api.inft.host';
    }
  } else if (process.env.NODE_ENV === 'mock') {
    BASE_URL = '';
  } else {
    // 生产环境
    if (url.includes('/api/')) {
      BASE_URL = 'https://vitae-api.inft.host';
    } else if (url.includes('/other-api/')) {
      BASE_URL = 'https://vitae-api.inft.host';
    } else {
      BASE_URL = 'https://vitae-api.inft.host';
    }
    // 用于将api地址转换成https
    BASE_URL = BASE_URL.replace('http://', 'https://');
  }
  return BASE_URL;
};

/**
 * @description 获取API URL，根据环境进行判断
 */
export const getApiUrl = (originUrl: string): string => {
  const baseUrl = import.meta.env.VITE_BASE_API_URL;
  // if (process.env.NODE_ENV === 'prod' || process.env.NODE_ENV === 'grey') {
  if (originUrl.includes('/api/')) return baseUrl + originUrl;
  if (originUrl.includes('/dashapi/'))
    return 'https://dash.inft.host/api/' + originUrl.split('dashapi/')[1];
  return baseUrl + originUrl;
};

/**
 * @description 获取 queryString
 */
export const getQueryString = (name: string): string | null => {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
  const r = window.location.search.substr(1).match(reg);
  if (r != null) return decodeURIComponent(r[2]);
  return null;
};

/**
 * @description 设置 queryString
 */
export function setSearchParam(param: string, value: string) {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set(param, value);
  const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
  window.history.replaceState({}, '', newUrl);
}

/**
 * @description 删除某个 queryString
 */
export function deleteSearchParam(key: string) {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(key);
  window.history.replaceState(
    {},
    '',
    `${window.location.pathname}?${searchParams.toString()}`
  );
}

/**
 * @description 获取 token
 */
export const getToken = (): string | null => {
  return getQueryString('token');
};

/**
 * @description throttle
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const throttle = function (fn: any, deplay: number) {
  let wait = false;
  return function (this: any, ...args: any) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    // eslint-disable-next-line prefer-rest-params
    if (!wait) {
      wait = true;
      setTimeout(() => {
        fn.apply(that, args);
        wait = false;
      }, deplay);
    }
  };
};

/**
 * @description debounce
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const debounce = function (excute: any, delay: number) {
  let timer: string | number | NodeJS.Timeout | undefined;
  return function (this: any, ...args: any) {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      excute.apply(this, args);
    }, delay);
  };
};

/**
 * @description isIos
 */
export const isIos = (): boolean => {
  const u = navigator.userAgent;
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
};

/**
 * @description 获取平台
 */
export const getPlatform = (): string => {
  const ua = navigator.userAgent;
  if (ua.match(/MicroMessenger/i)) {
    return 'MicroMessenger';
  }
  if (ua.match(/WeiBo/i)) {
    return 'WeiBo';
  }
  if (ua.match(/QQ/i)) {
    return 'QQ';
  }
  return '';
};

/**
 * @description 字符串获取时间戳，兼容ios
 */
export const getDate = (date: string): Date => {
  if (date.includes('T'))
    return new Date(date.replace(/-/g, '/').replace(/T/g, ' '));
  return new Date(date.replace(/-/g, '/'));
};

/**
 * @description 字符串获取时间戳，兼容ios
 */
export const toPercent = (val: number) => {
  return `${(Math.abs(val) * 100).toFixed(2)}%`;
};

/**
 * @description 复制到剪切板
 */
export const copyMsg = (msg: string) => {
  navigator.clipboard.writeText(msg);
};

/**
 * 格式化时间，间隔符为-
 * @param {Number} date 大小，单位时间戳13位
 * @return {String} 格式化后的大小 2021-03-12 20:21:02
 */
export const formatDate = (date: any) => {
  const da = new Date(date).toISOString();
  const ymd = da.split('T')[0];
  const hms = da.split('T')[1];
  return `${ymd} ${hms.split('.')[0]}`;
};

/**
 * 格式化时间，间隔符为-
 * @param {Number} date 大小，单位时间戳13位
 * @return {String} 格式化后的大小 2021-03-12 20:21:02
 */
export const formatTime = (date: any) => {
  const da = new Date(date);
  const year = da.getFullYear();
  const month = da.getMonth() + 1;
  const day = da.getDate();
  const hours = da.getHours();
  const mins = da.getMinutes();
  const secs = da.getSeconds();
  return `${year}-${month > 9 ? month : '0' + month}-${
    day > 9 ? day : '0' + day
  } ${hours > 9 ? hours : '0' + hours}:${mins > 9 ? mins : '0' + mins}:${
    secs > 9 ? secs : '0' + secs
  }`;
};

/**
 * 添加meta信息
 * @param {String} name name
 * @param {String} content content
 * @param {String} type name / property
 */
export const addHeadMeta = (name: string, content: string, type?: string) => {
  const meta = document.createElement('meta');
  meta.content = content;
  if (type) {
    meta[type] = name;
  } else {
    meta.name = name;
  }
  document.getElementsByTagName('head')[0].appendChild(meta);
};

/**
 * 判断字符长度
 * @param {String} val name
 */
export const getStringWidth = (val: string): number => {
  let len = 0;
  for (let i = 0; i < val.length; i++) {
    const length = val.charCodeAt(i);
    if (length >= 0 && length <= 128) {
      len += 1;
    } else {
      len += 2;
    }
  }
  return len;
};

/**
 * base64 to blob
 * @param {String} dataurl base64
 */
export const dataURLtoBlob = (dataurl: string) => {
  const arr: any = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

/**
 * 将blob转换为file
 * @param {String} val name
 */
export const blobToFile = (theBlob, fileName) => {
  theBlob.lastModifiedDate = new Date();
  theBlob.name = fileName;
  return theBlob;
};

/**
 * 打开新网址
 * @param {String} url url
 */
export const openBlankLink = (url) => {
  if (url.includes('https://') || url.includes('http://'))
    return window.open(url);
  return window.open('https://' + url);
};

/**
 * 滚动到底部
 * @param {String} url url
 */
export const scrollToBottom = () => {
  const currentScroll =
    document.documentElement.scrollTop || document.body.scrollTop; // 已经被卷掉的高度
  const clientHeight = document.documentElement.clientHeight; // 浏览器高度
  const scrollHeight = document.documentElement.scrollHeight; // 总高度
  if (scrollHeight - 10 > currentScroll + clientHeight) {
    window.requestAnimationFrame(scrollToBottom);
    window.scrollTo(
      0,
      currentScroll + (scrollHeight - currentScroll - clientHeight) / 2
    );
  }
};

/**
 * 格式化地址
 * @param {String} addr addr
 */

export const shortenString = (addr: string): string => {
  if (addr.length <= 8) {
    return addr;
  }

  const firstFour = addr.slice(0, 4);
  const lastFour = addr.slice(-4);

  return `${firstFour}...${lastFour}`;
};
/**
 * 格式化地址
 * @param {String} addr addr
 */

export const mergeUserInfo = (
  proposalList: ProposalListItem[],
  userInfoList: User[]
): ProposalListItem[] => {
  if (!userInfoList.length) return proposalList;
  const userInfoMap: Record<string, User> = userInfoList.reduce((acc, user) => {
    acc[user.address] = user;
    return acc;
  }, {});

  return proposalList.map((proposal) => {
    const userInfo = userInfoMap[proposal.author];
    if (userInfo) {
      return {
        ...proposal,
        name: userInfo.name,
        avatar: userInfo.avatar
      };
    }
    return proposal;
  });
};
