import toast from '@/components/toast';
import { User } from '@/types';
import request from '@/utils/request';

/**
 * @description GET user info
 */
export const apiGetUserInfo = async (address: string) => {
  const res: any = await request(`/api/v1/user/info?address=${address}`);
  if (res.code === 0) {
    return res.data;
  } else {
    if (+res.code === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('address');
      window.location.href = '/home';
    }
    toast.error(`${res.msg}`, { id: `${res.msg}` });
    return '';
  }
};

/**
 * @description update user info
 * @params data
 */
export const apiUpdateUserInfo = async (data: {
  avatar: string;
  desc: string;
  name: string;
  twitter: {
    name: string;
    requestTime?: number;
    sig?: string;
  };
}) => {
  const res: any = await request(`/api/v1/user/info`, {
    method: 'PUT',
    body: data
  });

  if (res.code === 0) {
    return res.data;
  } else {
    if (+res.code === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('address');
      window.location.href = '/home';
    }
    toast.error(`${res.msg}`, { id: `${res.msg}` });
    return '';
  }
};

/**
 * @description update user info
 * @params authorizationCode
 * @params redirectUri
 */
export const apiUserAuthTwitter = async (
  authorizationCode: string,
  redirectUri: string
) => {
  const res: any = await request(
    `/api/v1/oauth/twitter-user?authorizationCode=${authorizationCode}&redirectUri=${redirectUri}`
  );

  if (res.code === 0) {
    return res.data;
  } else {
    if (+res.code === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('address');
      window.location.href = '/home';
    }
    toast.error(`${res.msg}`, { id: `${res.msg}` });
    return '';
  }
};

/**
 * @description discord
 * @params authorizationCode
 * @params redirectUri
 */
export const apiUserAuthDiscord = async (
  authorizationCode: string,
  redirectUri: string
) => {
  const res: any = await request(
    `/api/v1/oauth/discord-user?authorizationCode=${authorizationCode}&redirectUri=${redirectUri}`
  );

  if (res.code === 0) {
    return res.data;
  } else {
    if (+res.code === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('address');
      window.location.href = '/home';
    }
    toast.error(`${res.msg}`, { id: `${res.msg}` });
    return '';
  }
};

/**
 * @description org discord
 * @params authorizationCode
 * @params redirectUri
 */
export const apiOrgAuthDiscord = async (
  authorizationCode: string,
  redirectUri: string
) => {
  const res: any = await request(
    `/api/v1/oauth/discord-admin-guild?authorizationCode=${authorizationCode}&redirectUri=${redirectUri}`
  );

  if (res.code === 0) {
    return res.data;
  } else {
    if (+res.code === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('address');
      window.location.href = '/home';
    }
    toast.error(`${res.msg}`, { id: `${res.msg}` });
    return '';
  }
};
/**
 * @description batchGetUserInfo
 * @params addresses
 *
 */
export const batchGetUserInfo = async (data: {
  addressList: string[];
}): Promise<User[]> => {
  try {
    const res = await request('/api/v1/user/batch', {
      method: 'POST',
      body: data
    });
    if (res.code === 0) {
      return res.data.items;
    } else {
      if (+res.code === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('address');
        window.location.href = '/home';
      }
      toast.error(`${res.msg}`, { id: `${res.msg}` });
      return [];
    }
  } catch (error: any) {
    toast.error(`${error.message}`, { id: `${error.message}` });
    return error;
  }
};
