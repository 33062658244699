import { Card } from '../card';
import Tooltip from '../tooltip';
import Typography from '../typography';

export const Books = (): JSX.Element => {
  return (
    <Tooltip
      placement="bottom"
      overlayStyle={{
        opacity: 1
      }}
      showArrow={false}
      overlay={
        <Card style={{ boxShadow: '0px 2px 10px 0 rgba(111,111,111,0.21)' }}>
          <div className="px-[20px] py-[12px]">
            <Typography
              variant="largeBody"
              className="my-[15px] cursor-pointer hover:text-[#c4c4c4]"
              onClick={() =>
                window.open(
                  'https://mirror.xyz/0x50BdCA147cb12eEeb82a81DDB6e2Da5A47D82C7a/-G6gw0bgzrUcjrgT4xQJjjJwP0eWQ6b3e8WkNoA0Gdk'
                )
              }
            >
              How-to-use Vitae3
            </Typography>

            <Typography
              variant="largeBody"
              className="my-[30px] cursor-pointer hover:text-[#c4c4c4]"
              onClick={() =>
                window.open(
                  'https://mirror.xyz/0x50BdCA147cb12eEeb82a81DDB6e2Da5A47D82C7a/oBO_pVaN_LHwyeY1Xx6s4qi0ZHt4J_8Go1iafmQmHKk'
                )
              }
            >
              Introducing to Vitae3
            </Typography>
            <Typography
              variant="largeBody"
              className="my-[15px] cursor-pointer hover:text-[#c4c4c4]"
              onClick={() =>
                window.open('https://discord.com/invite/Bg8Xz7YXWt')
              }
            >
              Request feature
            </Typography>
          </div>
        </Card>
      }
    >
      <Typography
        variant="subTitle"
        className="mx-[25px] cursor-pointer text-lg font-[500] text-[#1D2129]"
      >
        Explore
      </Typography>
    </Tooltip>
  );
};
