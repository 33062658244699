import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { apiGetUserInfo } from '@/apis/user';
import { useAppSetting } from '@/store/appSetting';
import { checkoutToken, loginOut, utilLogin } from '@/utils/checkLogin';

import { Card } from '../card';
import toast from '../toast';
import Tooltip from '../tooltip';
import Typography from '../typography';

export const Connect = (): JSX.Element => {
  const { auth, setAuth, setClaim, claim } = useAppSetting();
  const navigate = useNavigate();
  const ref = useRef(false);

  const [clickable, setClickable] = useState(true);
  const handleLink = async () => {
    setClickable(false);
    const isLogin = checkoutToken();
    if (!isLogin) {
      try {
        const res: any = await utilLogin();
        if (res) {
          setAuth(res.auth);
          navigate(`/user/${res.auth.address}`, { replace: true });
          setClickable(true);
        } else {
          navigate('/home', { replace: true });
          setClickable(true);
        }
      } catch (error) {
        setClickable(true);
      }
    } else {
      navigate(`/user/${auth.address}`, { replace: true });
      setClickable(true);
    }
  };

  // login out
  const handleLogout = () => {
    setAuth({
      address: '',
      token: '',
      name: '',
      avatar: '',
      uid: 0,
      isInWhiteList: false,
      number: 0,
      popup: 0,
      hasNewNotice: false
    });
    setClaim({
      ...claim,
      toClaim: 0
    });
    loginOut();
    navigate('/home', { replace: true });
  };

  // login
  const handleLogin = async () => {
    setClickable(false);
    try {
      const res: any = await utilLogin();
      if (res) {
        setAuth(res.auth);
      }
      setClickable(true);
    } catch (error) {
      setClickable(true);
    }
  };

  const handleAccountChange = (...args) => {
    const accounts = args[0];
    if (accounts.length === 0) {
      console.info('Please connect to metamask');
    } else if (accounts[0] !== auth.address) {
      toast.success('Account changed! Please verify account on metamask!');
      setTimeout(() => {
        handleLogin();
      }, 2500);
    }
  };

  const handleGetInfo = async () => {
    if (auth.address) {
      const res = await apiGetUserInfo(auth.address);
      localStorage.setItem('isInWhiteList', res.isInWhiteList);
      localStorage.setItem('popup', res.popup);
      localStorage.setItem('number', res.number);
      localStorage.setItem('hasNewNotice', res.hasNewNotice);
      localStorage.setItem('userAvatar', res.avatar);
      // hasNewNotice
      setAuth({
        ...auth,
        isInWhiteList: res.isInWhiteList,
        popup: res.popup,
        number: res.number,
        hasNewNotice: res.hasNewNotice
      });
    }
  };

  useEffect(() => {
    window.ethereum?.on('accountsChanged', handleAccountChange);
    return () => {
      window.ethereum?.removeListener('accountsChanged', handleAccountChange);
    };
  });

  useEffect(() => {
    if (ref.current) {
      ref.current = true;
    } else {
      if (auth.address) {
        handleGetInfo();
      }
    }
  }, []);

  return auth.address && auth.token ? (
    <Tooltip
      trigger={['hover']}
      showArrow={true}
      placement="bottom"
      overlayInnerStyle={{
        backgroundColor: '#fff',
        padding: 0,
        marginTop: '-4px'
      }}
      overlay={
        <Card style={{ boxShadow: '0px 2px 10px 0 rgba(111,111,111,0.21)' }}>
          <div className="px-[20px] py-[12px]">
            <Typography
              variant="largeBody"
              className="my-[15px] cursor-pointer hover:text-[#c4c4c4]"
              onClick={() => (clickable ? handleLink() : null)}
            >
              My Profile
            </Typography>
            {/* {claim.toClaim ? (
              <div
                className={`absolute top-[20px] right-[20px] inline-flex h-[10px] w-[10px] items-center justify-center rounded-full bg-[#FF165C]`}
              />
            ) : (
              ''
            )} */}
            <Typography
              variant="largeBody"
              className="mt-[30px] mb-[15px] cursor-pointer hover:text-[#c4c4c4]"
              onClick={handleLogout}
            >
              Disconnect
            </Typography>
          </div>
        </Card>
      }
    >
      <div className="flex h-[38px] w-[182px] cursor-pointer items-center justify-center rounded-[20px] border border-[#DFDBDB] bg-[#FFFFFF] px-[19px]">
        <img
          alt=""
          src={auth.avatar}
          className="mr-[6px] h-[24px] w-[24px] rounded-[25px] object-cover"
        />
        <Typography variant="subTitle" className="font-[400] text-[#1D2129]">
          {auth.address.replace(/(\w{6})\w+(\w{4})/, '$1...$2')}
        </Typography>
      </div>
    </Tooltip>
  ) : (
    <div
      onClick={() => (clickable ? handleLogin() : null)}
      className="flex h-[40px] cursor-pointer items-center justify-center rounded-full border-[1px] border-[#DFDBDB] bg-[#FFFFFF] px-[25px] py-[10px]"
    >
      <Typography
        variant="subTitle"
        className="text-lg font-[500] text-[#1D2129]"
      >
        Connect Wallet
      </Typography>
    </div>
  );
};
