import { CSSProperties, FC, HTMLAttributes, ReactNode } from 'react';

interface ModalProps extends HTMLAttributes<HTMLElement> {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
  bodyWidth?: string | number;
  bodyHeight?: string | number;
  padding?: string | number;
  overlay?: string;
}

const Modal: FC<ModalProps> = (props: ModalProps) => {
  return (
    <div
      className={`modal fixed inset-0 z-[120] flex items-center justify-center overflow-y-auto overflow-x-hidden bg-[rgba(48,54,64,0.4)] outline-none focus:outline-none ${props.className}`}
    >
      <div
        className={`relative my-6 mx-auto ${
          props.bodyWidth ? props.bodyWidth : 'w-[996px]'
        }`}
      >
        <div
          className={`modal-content relative flex w-full flex-col rounded-[28px] border-0 bg-white shadow-[0_4px_40px_0_#b8b8b8] outline-none focus:outline-none ${
            props.bodyHeight ? props.bodyHeight : 'h-[618px]'
          }`}
          style={{ boxShadow: '0px 4px 40px 0 #b8b8b8' }}
          {...props}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
