import { Link } from 'react-router-dom';

import toast from '../toast';
import Typography from '../typography';
import { CreateOrgLink } from './createOrgLink';
interface HoverCreateOrgProp {
  isInWhiteList?: boolean;
  isLogin?: boolean;
  isTooltip?: boolean;
  isMore?: boolean;
}

export const HoverCreateOrgLink = (prop: HoverCreateOrgProp): JSX.Element => {
  const mode = import.meta.env.MODE;
  const toolTipBtn = () => {
    switch (mode) {
      case 'test':
        return (
          <Link to="/organization/new?type=new">
            <Typography>+ New</Typography>
          </Link>
        );
      case 'testnet':
        if (prop.isInWhiteList) {
          return (
            <Link to="/organization/new?type=new">
              <Typography>+ New</Typography>
            </Link>
          );
        } else {
          return <CreateOrgLink title="+ New" />;
        }
      default:
        if (prop.isInWhiteList) {
          return (
            <Link to="/organization/new?type=new">
              <Typography>+ New</Typography>
            </Link>
          );
        } else {
          return <CreateOrgLink title="+ New" />;
        }
    }
  };

  const title = () => {
    switch (mode) {
      case 'test':
        return (
          <Link to="/organization/new?type=new">
            <Typography
              variant="subTitle"
              className="cursor-pointer text-base font-[400]"
            >
              Create Org
            </Typography>
          </Link>
        );
      case 'testnet':
        if (prop.isInWhiteList) {
          return (
            <Link to="/organization/new?type=new">
              <Typography
                variant="subTitle"
                className="cursor-pointer text-base font-[400]"
              >
                Create Org
              </Typography>
            </Link>
          );
        } else {
          return <CreateOrgLink />;
        }
      default:
        if (prop.isInWhiteList) {
          return (
            <Link to="/organization/new?type=new">
              <Typography
                variant="subTitle"
                className="cursor-pointer text-base font-[400]"
              >
                Create Org
              </Typography>
            </Link>
          );
        } else {
          return <CreateOrgLink />;
        }
    }
  };

  if (prop.isTooltip) {
    if (prop.isMore) {
      return (
        <Typography
          onClick={() =>
            toast.error('Only up to 3 organizations can be created')
          }
        >
          + New
        </Typography>
      );
    } else {
      return toolTipBtn();
    }
  } else {
    return title();
  }
};
