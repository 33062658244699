import Typography from '../typography';

interface CreatOrgProp {
  title?: string | undefined;
}

export const CreateOrgLink = (prop: CreatOrgProp): JSX.Element => {
  return (
    <a
      href="https://tally.so/r/3xXz4o"
      target="_blank"
      rel="noreferrer noopener"
    >
      <Typography
        variant="subTitle"
        className="mx-[25px] cursor-pointer text-lg font-[500] text-[#1D2129]"
      >
        {prop.title ?? 'Create Org'}
      </Typography>
    </a>
  );
};
