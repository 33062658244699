import { FC, HTMLAttributes, memo, ReactNode } from 'react';

import Button from '../button';

interface Props extends HTMLAttributes<HTMLElement> {
  children?: ReactNode;
  title?: string;
  confirmText?: string;
  cancelText?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  confrimChildren?: ReactNode;
}

const ModalFooter: FC<Props> = memo((props: Props) => {
  return (
    <div className="modal-footer h-[102px] rounded-[0_0_28px_28px] border-t-[1px] border-[#F1F1F1] px-[46px]">
      {props.children ? (
        props.children
      ) : (
        <div className="flex h-[100%] items-center justify-between">
          {props.cancelText ? (
            // <div
            //   onClick={props.onCancel}
            //   className="ml-[42px] mr-[24px] flex h-[54px] w-[157px] cursor-pointer items-center justify-center rounded-[27px] border border-[#b7b7b7]"
            //   style={{ filter: 'drop-shadow(0px 2px 10px #ecf4ee)' }}
            // >
            //   <Typography variant="body" className="text-center text-[#666]">
            //     {props.cancelText}
            //   </Typography>
            // </div>
            <Button
              variant="normal"
              onClick={props.onCancel}
              // className="ml-[42px] mr-[24px]"
            >
              {props.cancelText}
            </Button>
          ) : (
            <div></div>
          )}
          {props.confrimChildren ? (
            props.confrimChildren
          ) : (
            <Button
              variant="primary"
              onClick={props.onConfirm}
              // className="ml-[42px] mr-[24px]"
            >
              {props.confirmText}
            </Button>
            // <div
            //   onClick={props.onConfirm}
            //   className="ml-[42px] mr-[24px] flex h-[54px] w-[157px] cursor-pointer items-center justify-center rounded-[27px] border border-[#e8e8e8] bg-gradient-to-r from-[#f3ffd2] to-[#e8ffed] shadow-[0_2px_10px_0_#ecf4ee]"
            // >
            //   <p className="text-center text-[18px] font-[700] text-[#4e4e4e]">
            //     {props.confirmText}
            //   </p>
            // </div>
          )}
        </div>
      )}
    </div>
  );
});

ModalFooter.displayName = 'ModalFooter';

export default ModalFooter;
