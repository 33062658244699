import { createContext, ReactNode, useContext, useState } from 'react';

import { Organization } from '@/types';

interface OrganizationContextValue {
  organization: Organization | null;
  setOrganization: (organization: Organization | null) => void;
}

const OrganizationContext = createContext<OrganizationContextValue | undefined>(
  undefined
);

export const useOrganization = () => {
  const context = useContext(OrganizationContext);
  if (!context) {
    throw new Error(
      'useOrganization must be used within an OrganizationProvider'
    );
  }
  return context;
};

interface OrganizationProviderProps {
  children: ReactNode;
}

export const OrganizationProvider = ({
  children
}: OrganizationProviderProps) => {
  const [organization, setOrganization] = useState<Organization | null>(null);

  const value = {
    organization,
    setOrganization
  };

  return (
    <OrganizationContext.Provider value={value}>
      {children}
    </OrganizationContext.Provider>
  );
};
