import React, { FC, HTMLAttributes } from 'react';
import { Loading, Row } from '@nextui-org/react';

interface LoadingSpinnerProps extends HTMLAttributes<HTMLElement> {
  color?:
    | 'white'
    | 'default'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning'
    | 'error'
    | 'currentColor';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  justify?:
    | 'flex-start'
    | 'center'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  align?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline';
}

const LoadingSpinner: FC<LoadingSpinnerProps> = ({
  color = 'currentColor',
  size = 'sm',
  justify = 'center',
  align = 'center',
  ...rest
}) => {
  return (
    <Row justify={justify} align={align}>
      <Loading size={size} color={color} {...rest} />
    </Row>
  );
};

export default LoadingSpinner;
