import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import { captureConsoleIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { sentryConfig } from '../../build/config';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const sentry = () => {
  if (
    process.env.NODE_ENV === 'test' ||
    process.env.NODE_ENV === 'testnet' ||
    process.env.NODE_ENV === 'grey' ||
    process.env.NODE_ENV === 'prod'
  ) {
    Sentry.init({
      dsn: sentryConfig.dsn,
      release: sentryConfig.release,
      environment: sentryConfig.environment,
      integrations: [
        captureConsoleIntegration({ levels: ['info', 'error'] }),
        new BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
          tracingOrigins: ['localhost', /^\//]
        })
      ],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: sentryConfig.tracesSampleRate
    });
  }
};

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export default sentry;
