import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Container, Grid, Loading, Row } from '@nextui-org/react';

import LoadingSpinner from '@/components/LoadingSpinner';
import Empty from '@/assets/organization/empty.svg';
import Joined from '@/assets/organization/joined.svg';
import { apiGetOrgList, join } from '@/apis/org';
import { useOrganization } from '@/pages/organization/model/organizationContext';
import { useAppSetting } from '@/store/appSetting';
import request from '@/utils/request';
import useInfiniteScroll from '@/utils/useInfiniteScroll';

import SearchInput from './model/searchInput';

import '@/styles/rest.css';
import '@/styles/organization.css';

interface organizationSummary {
  avatar: string;
  desc: string;
  id: string;
  isJoined: boolean;
  memberCount: number;
  orgName: string;
}

const OrganizationList = () => {
  const [organizations, setOrganizations] = useState<organizationSummary[]>([]);
  const [params, setParams] = useState(new URLSearchParams());
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItem, setTotalItem] = useState(0);
  const { setOrganization } = useOrganization();
  const { joinedOrg, setJoinedOrg } = useAppSetting();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const handleEnter = (value: string) => {
    initParams();
    const newParams = new URLSearchParams(params);
    newParams.set('orgName', value);
    setCurrentPage(1);
    setParams(newParams);
    setOrganizations([]);
    setIsLoading(true);
    fetchData(newParams.toString());
    setIsSearch(true);
  };
  const initParams = () => {
    params.set('currentPage', '1');
    params.set('pageSize', '20');
  };
  const viewMore = () => {
    if (!hasNext) return;
    setIsLoadingMore(true);
    const newParams = new URLSearchParams(params);
    const current = currentPage + 1;
    setCurrentPage(current);
    newParams.set('currentPage', String(current));
    fetchData(newParams.toString(), true);
  };
  useInfiniteScroll(viewMore);

  const joinOrg = async (id, event) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      await join({ orgID: id });
      const res = await apiGetOrgList({ isAdmin: true, orgIdList: id });
      setJoinedOrg([res?.items[0], ...joinedOrg]);
      setOrganization(res?.items[0] ?? {});
      fetchData(params.toString());
    } catch (error) {
      console.error(error);
      // Display a user-friendly error message here
    }
  };

  const fetchData = (paramString: string, isAppend = false) => {
    request('/api/v1/org/search?' + paramString, {
      method: 'GET'
    })
      .then((response) => {
        if (response.code !== 0) {
          throw new Error('Network response was not ok');
        }
        return response.data;
      })
      .then((data) => {
        setOrganizations(
          isAppend ? organizations.concat(data.items) : data.items
        );
        setTotalItem(data.total);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
        setIsLoadingMore(false);
      });
  };
  useEffect(() => {
    setIsLoading(true);
    initParams();
    fetchData(params.toString());
  }, []);
  const navigate = useNavigate();
  const hasNext = totalItem >= currentPage * 8;

  const renderMemberCount = (memberCount) => {
    return memberCount >= 1000
      ? `${(memberCount / 1000).toFixed(1)}K`
      : memberCount;
  };

  const pressUpHandle = (event, item) => {
    navigate(`/organization/${item.id}/home`);
  };

  return (
    <>
      <Container className=" w-full justify-center overflow-y-scroll border-t px-[50px]">
        {/*<Input labelPlaceholder="Search Group" />*/}
        <Grid.Container>
          <Grid xs={12}>
            <SearchInput
              className="m-auto my-[40px] h-[40px] w-[668px]"
              placeholder="Search Group"
              onEnter={handleEnter}
            />
          </Grid>
        </Grid.Container>

        {isSearch && organizations.length == 0 && !isLoading && (
          <div className="flex min-h-[500px] w-full flex-col items-center justify-center">
            <img src={Empty} className="" />
            <text className="text-[14px] font-[400] text-[#A7AABA]">
              Oops, we can not find any results
            </text>
          </div>
        )}

        <div className="flex min-h-[500px] w-full items-center">
          {!isLoading ? (
            <div className="flex w-full flex-wrap justify-center gap-1 p-0 sm:gap-2 md:gap-4">
              {organizations.map((item: organizationSummary, index) => (
                <div
                  key={index}
                  className="mb-4 w-[calc(100%-16px)] min-w-[250px] sm:w-[calc(50%-16px)] md:w-[calc(25%-16px)]"
                >
                  <Card
                    isPressable
                    onClick={(event) => pressUpHandle(event, item)}
                    className="remove-card-shadow-update-border flex h-[320px] flex-col items-center justify-center rounded-3xl py-5 font-[Roboto]"
                  >
                    <img
                      className="h-[76px] w-[76px] rounded-full border border-[#A7AABA]"
                      src={item.avatar}
                      alt="avatar"
                    />
                    <div className="mt-2 mb-1 text-center text-2xl font-bold text-[#1D2129]  line-clamp-1">
                      {item.orgName}
                    </div>
                    <div className="mb-2 text-sm font-normal text-[#A7AABA]">
                      {renderMemberCount(item.memberCount)} Members
                    </div>
                    <div className="h-[70px]">
                      <div className="text-ellipsis break-all px-4 text-center text-sm font-normal text-[#1D2129] line-clamp-3">
                        {item.desc}
                      </div>
                    </div>
                    <button
                      disabled={item.isJoined}
                      onClick={(event) => joinOrg(item.id, event)}
                      className={`${
                        item.isJoined
                          ? 'cursor-not-allowed bg-gray-50 disabled:opacity-75'
                          : 'custom-btn-border cursor-pointer'
                      } flex w-[154px] items-center justify-center rounded-full border border-[#A7AABA] py-2 text-base font-normal text-[#1D2129]`}
                    >
                      {item.isJoined && <img src={Joined} className="mr-2" />}
                      {item.isJoined ? 'Joined' : 'Join'}
                    </button>
                  </Card>
                </div>
              ))}
            </div>
          ) : (
            <LoadingSpinner align={`center`} color="primary" />
          )}
        </div>

        {isLoadingMore && (
          <Row justify="center" className={`h-[50px]`}>
            <Loading size="sm" color={'currentColor'} />
          </Row>
        )}
      </Container>
    </>
  );
};

export default OrganizationList;
