import { FC, HTMLAttributes, useEffect, useRef } from 'react';

import Button from '@/components/button';
import { Modal, ModalBody, ModalHeader } from '@/components/modal/';
import Typography from '@/components/typography';
import SloganBg from '@/assets/website/slogan_bg.svg';
import iconDiscord from '@/assets/welcome/discord.svg';
import iconTwitter from '@/assets/welcome/twitter.svg';
import infoCircle from '@/assets/welcome/vitae3.png';
import { apiPopupPost } from '@/apis/login';

interface Props extends HTMLAttributes<HTMLElement> {
  show?: false;
  num?: number;
  onClose?: () => void;
}

const WelcomeModal: FC<Props> = (props: Props) => {
  const ref = useRef(true);

  const handlePost = async () => {
    apiPopupPost();
    localStorage.setItem('popup', '1');
  };

  useEffect(() => {
    if (ref.current) {
      ref.current = false;
      handlePost();
    }
  }, []);

  return (
    <Modal>
      <ModalHeader title="Welcome onboard" onClose={props.onClose} />
      <ModalBody>
        <div
          className="flex h-[360px] w-full flex-col items-center"
          style={{
            backgroundImage: `url(${SloganBg})`,
            backgroundPosition: 'center 60%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        >
          <div className="mt-[66px] flex w-[100%] flex-col items-center">
            <Typography variant="title" className="flex items-center">
              Welcome!
              <img
                src={infoCircle}
                alt=""
                className="ml-[12px] h-[24px] w-[88px]"
              />
            </Typography>
            <Typography variant="title" className="mt-[24px]">
              You’re the {props.num || 0} Vitae member
            </Typography>
            <Typography className="mt-[42px]">
              For future airdrop info 🎁
            </Typography>
            <div className="mt-[26px] flex h-[72px] w-[100%] max-w-[482px] items-center justify-between rounded-[10px] border border-[#f5f5f5] bg-[#FFFFFF] px-[30px]">
              <Typography className="flex items-center">
                <img className="mr-[12px]" src={iconTwitter} alt="" />
                Follow @Vitae3
              </Typography>
              <Button
                onClick={() => {
                  window.open(
                    'https://twitter.com/intent/follow?original_referer=https://vitae3.me&screen_name=vitae3me'
                  );
                }}
                variant="outline"
              >
                Follow
              </Button>
            </div>
            <div className="mt-[16px] flex h-[72px] w-[100%] max-w-[482px] items-center justify-between rounded-[10px] border border-[#f5f5f5] bg-[#FFFFFF] px-[30px]">
              <Typography className="flex items-center">
                <img className="mr-[12px]" src={iconDiscord} alt="" />
                Join Vitae Community
              </Typography>
              <Button
                onClick={() => {
                  window.open('https://discord.gg/Bg8Xz7YXWt');
                }}
                variant="outline"
              >
                Join
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
      <div className="flex justify-center">
        <Button
          onClick={props.onClose}
          variant="normal"
          className="ml-[42px] mr-[24px] h-[36px] w-[140px] "
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default WelcomeModal;
