import { FC, HTMLAttributes, memo, ReactNode } from 'react';

import Typography from '../typography';

interface Props extends HTMLAttributes<HTMLElement> {
  children?: ReactNode;
  title?: string;
  onBack?: () => void;
  showBack?: boolean;
}

const CardHeader: FC<Props> = memo(
  ({ children, className, title, onBack, showBack }: Props) => {
    return (
      <div
        className={`card-header flex h-[70px] items-center justify-start rounded-t-[22px] border-b-[1px] border-[#E8E8E8] bg-white px-4 font-[600] md:px-[46px] ${className}`}
      >
        {children ? (
          children
        ) : (
          <p className="flex items-center text-left text-[#787878] ">
            {showBack ? (
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                className="mr-[16px] cursor-pointer"
                onClick={onBack}
              >
                <rect width="40" height="40" rx="20" fill="#F3F3F3" />
                <path
                  d="M23 14L17 20L23 26"
                  stroke="#1D2129"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            ) : null}
            <Typography variant="subTitle" tag="span">
              {title}
            </Typography>
          </p>
        )}
      </div>
    );
  }
);

CardHeader.displayName = 'CardHeader';

export default CardHeader;
