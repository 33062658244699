import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Badge from '@/components/badge';
import iconMail from '@/assets/mail.svg';
import { apiGetUserInfo } from '@/apis/user';
import { useAppSetting } from '@/store/appSetting';
import { checkoutToken, utilLogin } from '@/utils/checkLogin';

export const Mail = (): JSX.Element => {
  const ref = useRef(false);
  const { auth, setAuth } = useAppSetting();
  const navigate = useNavigate();
  const [clickable, setClickable] = useState(true);

  const handleLink = async () => {
    if (!clickable) return;
    setClickable(false);
    const isLogin = checkoutToken();
    if (!isLogin) {
      try {
        const res: any = await utilLogin();
        if (res) {
          setAuth(res.auth);
          navigate(`/profile/claim`, { replace: true });
          setClickable(true);
        } else {
          navigate('/home', { replace: true });
          setClickable(true);
        }
      } catch (error) {
        setClickable(true);
      }
    } else {
      navigate(`/profile/claim`, { replace: true });
      setClickable(true);
    }
  };

  const handleGetInfo = async () => {
    if (auth.address) {
      const res = await apiGetUserInfo(auth.address);
      localStorage.setItem('isInWhiteList', res.isInWhiteList);
      localStorage.setItem('popup', res.popup);
      localStorage.setItem('number', res.number);
      localStorage.setItem('hasNewNotice', res.hasNewNotice);
      // hasNewNotice
      setAuth({
        ...auth,
        isInWhiteList: res.isInWhiteList,
        popup: res.popup,
        number: res.number,
        hasNewNotice: res.hasNewNotice
      });
    }
  };

  useEffect(() => {
    if (ref.current) {
      setInterval(() => {
        handleGetInfo();
      }, 1000 * 60 * 1 * 5);
    } else {
      ref.current = true;
    }
  }, []);

  return (
    <div className="mr-[24px]">
      <Badge
        onClick={handleLink}
        content={
          <img
            src={iconMail}
            className="mb-[14px] ml-[25px] h-[48px] w-[48px]"
            alt=""
          />
        }
        // text={claim.toClaim}
        // showCircle={auth.hasNewNotice}
        showCircle={auth.hasNewNotice}
      />
    </div>
  );
};
