import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { apiGetOrgList } from '@/apis/org';
import SideBar from '@/pages/organization/model/sideBar';
import { useAppSetting } from '@/store/appSetting';

import { OrganizationProvider } from './model/organizationContext';

// import Footer from '@/components/footer';
import '@/styles/rest.css';

const Home = () => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const { auth, setJoinedOrg } = useAppSetting();
  const fetchJoinedOrgList = async () => {
    const res = await apiGetOrgList({});
    setJoinedOrg(res?.items ?? []);
  };
  useEffect(() => {
    if (auth.token) {
      fetchJoinedOrgList();
    }
  }, [auth.token]);

  useEffect(() => {
    setHeaderHeight(
      document.getElementById('header-navbar-height-id')?.offsetHeight ?? 0
    );
  }, []);

  return (
    <div
      className="flex flex-nowrap"
      style={{ height: `calc(100vh - ${headerHeight + 2}px)` }}
    >
      <OrganizationProvider>
        <SideBar />
        <div className="h-min-[80%] absolute ml-[60px] w-[calc(100%-60px)] bg-[#F6F5FB]">
          <Outlet />
        </div>
      </OrganizationProvider>
    </div>
  );
};

export default Home;
