import { Outlet } from 'react-router-dom';

import Navbar from '../navbar';

const Layout = (): JSX.Element => {
  return (
    <div className="bg-cover bg-top bg-no-repeat">
      <Navbar />
      <div className="absolute top-[64px] w-full">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
