import useGaTracker from '@/utils/useGaTracker';

import RouterGurad from './gurad';
import H5Routes from './h5';
import MainRoutes from './MainRoutes';

// const basename = '';

export default function ThemeRoutes() {
  useGaTracker();
  // return useRoutes([MainRoutes, AuthenticationRoutes], basename);
  return RouterGurad([MainRoutes, H5Routes]);
}
