import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import logoTitle from '@/assets/logo_title.svg';
import { useAppSetting } from '@/store/appSetting';

import Typography from '../typography';
import NavbarMobile from './mobile';
import NavbarPc from './pc';
import WelcomeModal from './welcomeModal';

const Navbar = (): JSX.Element => {
  const { auth } = useAppSetting();
  const [showWelcome, setShowWelcome] = useState(true);

  const [isPc, setPc] = useState(true);

  const handleResize = () => {
    const docEl = document.documentElement;
    // const dpr = window.devicePixelRatio || 1;
    if (docEl.clientWidth > 1024) {
      setPc(true);
    } else {
      setPc(false);
    }
  };

  useEffect(() => {
    if (window) {
      handleResize();
      window.addEventListener('resize', handleResize);
      window.addEventListener('pageshow', function (e) {
        if (e.persisted) {
          handleResize();
        }
      });
    }
  }, []);

  return (
    <div id="header-navbar-height-id">
      {/* {import.meta.env.MODE !== 'prod' ? <Testtip /> : null} */}
      <div className="navbar fixed z-20 flex h-[64px] w-[100vw] items-center justify-between border-b-[1px] border-[#DFDBDB] bg-white pl-[19px] pr-[50px]">
        <Link to="/home">
          <div className="visible flex sm:visible">
            <img
              className="h-[33px] w-[42px]"
              src={logoTitle}
              alt="logo title"
            />
            {import.meta.env.MODE === 'testnet' ? (
              <Typography
                variant="subTitle"
                className="ml-[14px] leading-[28px]"
              >
                TestNet: Mumbai
              </Typography>
            ) : import.meta.env.MODE === 'test' ? (
              <Typography
                variant="subTitle"
                className="ml-[24px] leading-[28px] text-gray-300"
              >
                Development: Mumbai {new Date().getTime()}
              </Typography>
            ) : (
              ''
            )}
          </div>
        </Link>
        {isPc ? <NavbarPc /> : <NavbarMobile />}
        {auth.token && showWelcome && !auth.popup ? (
          <WelcomeModal
            num={auth.number}
            onClose={() => setShowWelcome(false)}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Navbar;
