import toast from '@/components/toast';
import request from '@/utils/request';

/**
 * @description GET 获取我的sbt List
 * @params address 地址
 */
export const apiGetSbtList = async (data: {
  currentPage?: number;
  pageSize?: number;
  orgId?: string;
  contractAddress?: string;
  tokenId?: number;
  state?: string;
  type?: string;
}) => {
  const res: any = await request(
    `/api/v1/sbt/mine?currentPage=${
      data.currentPage ? data.currentPage : 1
    }&pageSize=${data.pageSize ? data.pageSize : 10}&state=${
      data.state ? data.state : '0,1,2'
    }${data.orgId ? `&orgId=${data.orgId}` : ''}${
      data.tokenId ? `&tokenId=${data.tokenId}` : ''
    }${data.contractAddress ? `&contractAddress=${data.contractAddress}` : ''}${
      data.type ? `&type=${data.type}` : ''
    }`
  );
  if (res.code === 0) {
    return res.data;
  } else {
    if (+res.code === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('address');
      window.location.href = '/home';
    }
    // 临时去除错误提示，大多情况是前置为登录，有另外的一个提示
    // toast.error(`${res.msg}`, { id: `${res.msg}` });
    return '';
  }
};

/**
 * @description GET 获取组织的sbt list
 * @params address 地址
 */
export const apiGetOrgSbtList = async (data: {
  currentPage?: number;
  pageSize?: number;
  orgId: string;
  state?: string;
  type?: string;
}) => {
  const res: any = await request(
    `/api/v1/sbt/org?currentPage=${
      data.currentPage ? data.currentPage : 1
    }&pageSize=${data.pageSize ? data.pageSize : 10}&state=${
      data.state ? data.state : '0,1,2'
    }${data.orgId ? `&orgId=${data.orgId}` : ''}${
      data.type ? `&type=${data.type}` : ''
    }`
  );
  if (res.code === 0) {
    return res.data;
  } else {
    if (+res.code === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('address');
      window.location.href = '/home';
    }
    toast.error(`${res.msg}`, { id: `${res.msg}` });
    return '';
  }
};

/**
 * @description 创建 Sbt
 * @params data
 */
export const apiCreateSbt = async (data: {
  receiverAddressList: string[];
  avatar?: string;
  desc?: string;
  name?: string;
  message?: string;
  orgId: string;
  sig?: string;
  tokenId: number;
  type?: string;
}) => {
  const res: any = await request(`/api/v1/sbt/create`, {
    method: 'POST',
    body: data
  });

  if (res.code === 0) {
    return res.data;
  } else {
    if (+res.code === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('address');
      window.location.href = '/home';
    }
    toast.error(`${res.msg}`, { id: `${res.msg}` });
    return '';
  }
};

/**
 * @description Claim Sbt
 * @params data
 */
export const apiClaimSbt = async (data: {
  action: string; // accept=接受，reject=拒绝
  message?: string;
  orgId?: string;
  sig?: string;
  tokenId?: number;
  userSbtId: number;
}) => {
  const res: any = await request(`/api/v1/sbt/claim`, {
    method: 'POST',
    body: data
  });

  if (res.code === 0) {
    return data.action === 'accept' ? res.data : true;
  } else {
    if (+res.code === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('address');
      window.location.href = '/home';
    }
    toast.error(`${res.msg}`, { id: `${res.msg}` });
    return '';
  }
};

/**
 * @description GET 获取组操作状态
 * @params address 地址
 */
export const apiOperationStatus = async (operationId: string) => {
  const res: any = await request(`/api/v1/operation?id=${operationId}`);
  if (res.code === 0) {
    return res.data;
  } else {
    if (+res.code === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('address');
      window.location.href = '/home';
    }
    toast.error(`${res.msg}`, { id: `${res.msg}` });
    return '';
  }
};

/**
 * @description 创建 Sbt
 * @params data
 */
export const apiSendSbt = async (data: {
  receiverAddressList: string[];
  message?: string;
  orgId: string;
  sig?: string;
  tokenId: number;
}) => {
  const res: any = await request(`/api/v1/sbt/send`, {
    method: 'POST',
    body: data
  });

  if (res.code === 0) {
    return res.data;
  } else {
    if (+res.code === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('address');
      window.location.href = '/home';
    }
    toast.error(`${res.msg}`, { id: `${res.msg}` });
    return '';
  }
};

/**
 * @description 预览 Sbt
 * @params data
 */
export const apiPreviewSbt = async (data: {
  orgId: string;
  sbtAvatar: string;
  sbtName: string;
  type: string;
  isUserSbt: boolean;
  userAvatar: string;
  username: string;
}) => {
  const res: any = await request(
    `/api/v1/sbt/preview?orgId=${data.orgId}&sbtAvatar=${data.sbtAvatar}&sbtName=${data.sbtName}&type=${data.type}&isUserSbt=${data.isUserSbt}&userAvatar=${data.userAvatar}&username=${data.username}`
  );
  if (res.code === 0) {
    return res.data;
  } else {
    if (+res.code === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('address');
      window.location.href = '/home';
    }
    toast.error(`${res.msg}`, { id: `${res.msg}` });
    return '';
  }
};

/**
 * @description 预览 Sbt detail
 * @params data
 */
export const apiGetSbtDetail = async (data: {
  contractAddress: string;
  tokenId: number;
  userAddress?: string;
}) => {
  const res: any = await request(
    `/api/v1/sbt/detail?contractAddress=${data.contractAddress}&tokenId=${
      data.tokenId
    }${data.userAddress ? `&userAddress=${data.userAddress}` : ''}`
  );
  if (res.code === 0) {
    return res.data;
  } else {
    if (+res.code === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('address');
      window.location.href = '/home';
    }
    toast.error(`${res.msg}`, { id: `${res.msg}` });
    return '';
  }
};

/**
 * @description GET 获取用户的sbt List
 * @params address 地址
 */
export const apiGetUserSbtList = async (data: {
  currentPage?: number;
  pageSize?: number;
  orgId?: string;
  state?: string;
  type?: string;
  address?: string;
}) => {
  const res: any = await request(
    `/api/v1/sbt/user?currentPage=${
      data.currentPage ? data.currentPage : 1
    }&pageSize=${data.pageSize ? data.pageSize : 10}&state=${
      data.state ? data.state : '0,1,2'
    }${data.orgId ? `&orgId=${data.orgId}` : ''}${
      data.type ? `&type=${data.type}` : ''
    }${data.address ? `&address=${data.address}` : ''}`
  );
  if (res.code === 0) {
    return res.data;
  } else {
    if (+res.code === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('address');
      window.location.href = '/home';
    }
    // 临时去除错误提示，大多情况是前置为登录，有另外的一个提示
    // toast.error(`${res.msg}`, { id: `${res.msg}` });
    return '';
  }
};

/**
 * @description 修噶 Sbt 数据
 * @params data
 */
export const apiGetSbtEdit = async (data: {
  contractAddress: string;
  avatar: string;
  desc: string;
  name: string;
  tokenId: number;
}) => {
  const res: any = await request(`/api/v1/sbt/modify`, {
    method: 'PUT',
    body: data
  });
  if (res.code === 0) {
    return res.data;
  } else {
    if (+res.code === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('address');
      window.location.href = '/home';
    }
    toast.error(`${res.msg}`, { id: `${res.msg}` });
    return '';
  }
};

/**
 * @description GET 查询某个组织的sbt发放历史
 * @params address 地址
 */
export const apiGetOrgSbtSendList = async (data: {
  currentPage?: number;
  pageSize?: number;
  orgId?: string;
  state?: string;
  type?: string;
  sender?: string;
  timeFrom?: string;
  timeTo?: string;
  keyword?: string;
}) => {
  const res: any = await request(
    `/api/v1/sbt/send?currentPage=${
      data.currentPage ? data.currentPage : 1
    }&pageSize=${data.pageSize ? data.pageSize : 10}&state=${
      data.state ? data.state : '0,1,2'
    }${data.orgId ? `&orgId=${data.orgId}` : ''}${
      data.type ? `&type=${data.type}` : ''
    }${data.sender ? `&sender=${data.sender}` : ''}${
      data.timeFrom ? `&timeFrom=${data.timeFrom}` : ''
    }${data.timeTo ? `&timeTo=${data.timeTo}` : ''}${
      data.keyword ? `&name=${data.keyword}` : ''
    }`
  );
  if (res.code === 0) {
    return res.data;
  } else {
    if (+res.code === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('address');
      window.location.href = '/home';
    }
    return '';
  }
};

/**
 * @description GET 查询某个组织的sender List
 * @params address 地址
 */
export const apiGetOrgSbtSenderList = async (orgId: string) => {
  const res: any = await request(`/api/v1/sbt/org-sender?orgId=${orgId}`);
  if (res.code === 0) {
    return res.data;
  } else {
    if (+res.code === 401) {
      localStorage.removeItem('token');
      localStorage.removeItem('address');
      window.location.href = '/home';
    }
    return '';
  }
};
