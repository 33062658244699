import { FC, HTMLAttributes, memo, ReactNode } from 'react';

import Typography from '../typography';

interface Props extends HTMLAttributes<HTMLElement> {
  children?: ReactNode;
  title?: string;
  onClose?: () => void;
  onBack?: () => void;
  showBack?: boolean;
}

const ModalHeader: FC<Props> = memo((props: Props) => {
  return (
    <div className="modal-header flex h-[80px] items-center justify-between rounded-[28px_28px_0_0] bg-[#FBFBFB] px-[46px]">
      {props.children ? (
        props.children
      ) : (
        <p className="flex items-center text-left">
          {props.showBack ? (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              className="mr-[8px] cursor-pointer"
              onClick={props.onBack}
            >
              <path
                d="M13.8302 19C13.6808 19.0005 13.5332 18.9676 13.3982 18.9035C13.2632 18.8395 13.1443 18.7461 13.0502 18.63L8.22016 12.63C8.07308 12.4511 7.99268 12.2267 7.99268 11.995C7.99268 11.7634 8.07308 11.539 8.22016 11.36L13.2202 5.36003C13.3899 5.15581 13.6338 5.02739 13.8982 5.00301C14.1627 4.97863 14.4259 5.06029 14.6302 5.23003C14.8344 5.39977 14.9628 5.64368 14.9872 5.90811C15.0116 6.17253 14.9299 6.43581 14.7602 6.64003L10.2902 12L14.6102 17.36C14.7324 17.5068 14.8101 17.6856 14.834 17.8751C14.8579 18.0647 14.827 18.2571 14.7449 18.4296C14.6629 18.6021 14.5331 18.7475 14.371 18.8486C14.2089 18.9497 14.0212 19.0023 13.8302 19Z"
                fill="#787878"
              ></path>
            </svg>
          ) : null}
          <Typography variant="subTitle" className="text-[#787878]">
            {props.title}
          </Typography>
        </p>
      )}
      <div
        className="float-right mr-[10px] cursor-pointer"
        onClick={props.onClose}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          <path
            d="M15.6584 0.341639C15.2029 -0.113856 14.4644 -0.113856 14.0089 0.341639L8.00001 6.3505L1.99113 0.341622C1.53563 -0.113874 0.797129 -0.113874 0.341633 0.341621C-0.113862 0.797116 -0.113862 1.53562 0.341633 1.99112L6.35051 7.99999L0.341622 14.0089C-0.113874 14.4644 -0.113874 15.2029 0.341622 15.6584C0.797118 16.1139 1.53562 16.1139 1.99112 15.6584L8.00001 9.64949L14.0089 15.6584C14.4644 16.1139 15.2029 16.1139 15.6584 15.6584C16.1139 15.2029 16.1139 14.4644 15.6584 14.0089L9.6495 7.99999L15.6584 1.99113C16.1139 1.53564 16.1139 0.797135 15.6584 0.341639Z"
            fill="#787878"
          ></path>
        </svg>
      </div>
    </div>
  );
});

ModalHeader.displayName = 'ModalHeader';

export default ModalHeader;
