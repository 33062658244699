import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { apiGetOrgList } from '@/apis/org';
import { apiGetSbtList } from '@/apis/sbt';
import { useAppSetting } from '@/store/appSetting';
import { checkoutToken } from '@/utils/checkLogin';

import { Card } from '../card';
import Tooltip from '../tooltip';
import Typography from '../typography';
import { HoverCreateOrgLink } from './hoverCreateOrgLink';

export const Routes = (): JSX.Element => {
  const { auth, claim, setClaim, setAdminOrg, adminOrg, setJoinedOrg } =
    useAppSetting();
  const navigate = useNavigate();
  const { id } = useParams();
  // const isNew = searchParams.get('type');
  const [activeItem, setActiveItem] = useState({
    id: '',
    name: '',
    slug: ''
  });

  const [isMore, setIsMore] = useState(false);

  const renderOrgRoute = () => {
    const isLogin = checkoutToken();
    // not login
    if (!isLogin)
      return (
        <HoverCreateOrgLink
          isInWhiteList={auth.isInWhiteList}
          isLogin={false}
          isTooltip={false}
        />
      );

    // not have a org
    if (adminOrg.length === 0)
      return (
        <HoverCreateOrgLink
          isInWhiteList={auth.isInWhiteList}
          isLogin={true}
          isTooltip={false}
        />
      );
    return (
      <Tooltip
        trigger={['hover']}
        showArrow={false}
        placement="bottom"
        overlayInnerStyle={{
          marginTop: '10px',
          backgroundColor: '#fff',
          padding: 0
        }}
        overlayStyle={{
          opacity: 1
        }}
        overlay={
          <Card
            style={{ boxShadow: '0px 2px 10px 0 rgba(111,111,111,0.21)' }}
            className="flex min-w-[159px] cursor-pointer flex-col rounded-lg bg-white pt-3"
          >
            {adminOrg.map((e: any, index: any) => (
              <Typography
                className={` p-[16px] ${index ? '' : 'rounded-t-[10px]'}`}
                key={e.id}
                // to={`/org/${e.id}`}
                onClick={() => {
                  localStorage.setItem('activeItemIndex', e.slug);
                  setActiveItem(e);
                  navigate(`/org/${e.slug}`);
                }}
                variant="largeBody"
              >
                {e.name}
              </Typography>
            ))}
            <div className="flex h-[52px] items-center  rounded-b-[10px] border-t border-[#b7b7b7] bg-neutral-100 px-[22px] text-lg leading-[20px] text-[#4e4e4e]">
              <HoverCreateOrgLink
                isInWhiteList={auth.isInWhiteList}
                isTooltip={true}
                isLogin={true}
                isMore={isMore}
              />
            </div>
          </Card>
        }
      >
        <div
          className={
            location.pathname === `/org/${activeItem ? activeItem?.slug : ''}`
              ? 'cursor-pointer border-b-[3px] border-[#4E4E4E] leading-[32px]'
              : 'cursor-pointer '
          }
        >
          <Typography
            variant="largeBody"
            onClick={() => navigate(`/org/${activeItem.slug}`)}
          >
            {activeItem?.name}
          </Typography>
        </div>
      </Tooltip>
    );
  };

  const handleGetOrgList = async () => {
    const res = await apiGetOrgList({ isAdmin: true });
    if (res) {
      if (res.items !== adminOrg) {
        setAdminOrg(res.items);
        if (res.items.length > 0) {
          if (location.pathname === `/org/${id}`) {
            const activeItemList = res.items.filter(
              (item: any) => item.slug === id
            );
            if (activeItemList.length === 0) {
              setActiveItem({
                id: res.items[0].id,
                name: res.items[0].name,
                slug: res.items[0].slug
              });
              localStorage.setItem('activeItemIndex', res.items[0].slug);
            } else {
              setActiveItem({
                id: activeItemList[0].id,
                name: activeItemList[0].name,
                slug: activeItemList[0].slug
              });
              localStorage.setItem('activeItemIndex', activeItemList[0].slug);
            }
            // if (isNew) {
            //   activeItem = org[0];
            // }
          } else {
            if (localStorage.getItem('activeItemIndex')) {
              const cahce = res.items.filter(
                (e) => e.slug === localStorage.getItem('activeItemIndex')
              );
              if (cahce.length > 0) {
                setActiveItem(cahce[0]);
              } else {
                setActiveItem(res.items[0]);
                localStorage.setItem('activeItemIndex', res.items[0].slug);
              }
            } else {
              setActiveItem(res.items[0]);
              localStorage.setItem('activeItemIndex', res.items[0].slug);
            }
          }
          // 处理创建人不能创建超过三个
          const cacheData = res.items.filter(
            (item) =>
              item.creator.address.toLowerCase() === auth.address.toLowerCase()
          );
          if (cacheData.length > 2) {
            setIsMore(true);
          }
        }
      }
    }
  };

  const handleGetSbt = async () => {
    const res = await apiGetSbtList({
      state: '0',
      type: 'xp,id'
    });
    if (res && res.total) {
      setClaim({
        ...claim,
        toClaim: res.total
      });
    }
  };

  const fetchMyOrgList = async () => {
    const res = await apiGetOrgList({});
    setJoinedOrg(res?.items ?? []);
  };

  useEffect(() => {
    if (auth.token) {
      handleGetOrgList();
      handleGetSbt();
      fetchMyOrgList();
    }
  }, [auth.token]);

  return <>{renderOrgRoute()}</>;
};
