import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Text } from '@nextui-org/react';

import { HomeIcon } from '@/components/icons/homeIcon';
import { PointIcon } from '@/components/icons/pointIcon';
import { ProjectIcon } from '@/components/icons/projectIcon';
import { ProposalIcon } from '@/components/icons/proposalIcon';

const NavBar: () => JSX.Element = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id: orgId } = useParams();
  const [links, setLinks] = useState<Map<string, string>>(
    new Map<string, string>()
  );

  useEffect(() => {
    setLinks(
      new Map<string, string>([
        ['Home', `/organization/${orgId}/home`],
        ['Proposals', `/organization/${orgId}/proposal-list`],
        ['Contribution', `/organization/${orgId}/points`]
        // ['Projects', '']
      ])
    );
  }, [orgId]);

  useEffect(() => {
    if (location.pathname.endsWith('home')) {
      setActive(0);
    }
    if (location.pathname.endsWith('list')) {
      setActive(1);
    }
    if (location.pathname.includes('points')) {
      setActive(2);
    }
  });
  const [active, setActive] = useState<number>(0);
  const renderIcon = (index: number) => {
    const color = isActive(index) ? `#345EDC` : `#1D2129`;

    switch (index) {
      case 0:
        return <HomeIcon size={18} fill={color} />;
      case 1:
        return <ProposalIcon size={18} fill={color} />;
      case 2:
        return <PointIcon size={18} fill={color} />;
      case 3:
        return <ProjectIcon size={18} fill={color} />;
    }
  };

  const handleEvent = (index: number, link: string) => {
    setActive(index);
    navigate(link);
  };
  const isActive = (index: number) => {
    return index === active;
  };
  return (
    <div className="mt-7 mr-4 grid grid-cols-4 md:grid-cols-1">
      {Array.from(links.entries()).map(([key, value], index) => {
        return (
          <div
            className="mb-[24px] flex w-full cursor-pointer items-center"
            key={index}
            onClick={() => handleEvent(index, value)}
          >
            <i
              className={`invisible mr-[30px] h-[30px] w-[4px] rounded-r-[4px] md:visible ${
                isActive(index) ? ' bg-[#345EDC]' : ''
              }`}
            />
            {renderIcon(index)}
            <Text
              className="ml-[12px]"
              size={14}
              weight={`medium`}
              color={isActive(index) ? `#345EDC` : `#1D2129`}
            >
              {key}
            </Text>
          </div>
        );
      })}
    </div>
  );
};
export default NavBar;
