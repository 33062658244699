import { FC, HTMLAttributes, memo, ReactNode } from 'react';

import IconLogoLoading from '@/assets/logo_loading.svg';

interface ButtonProps extends HTMLAttributes<HTMLElement> {
  children?: ReactNode;
  className?: string;
  variant?: 'primary' | 'normal' | 'outline';
  disable?: boolean;
  showLoading?: boolean;
}

const Button: FC<ButtonProps> = memo(
  ({
    className,
    children,
    variant,
    disable,
    showLoading = false,
    onClick,
    ...rest
  }: ButtonProps) => {
    const primaryButton = () => (
      <div
        className={`flex min-w-[140px] cursor-pointer items-center justify-center rounded-[10px] border px-[36px] py-[10px] text-[14px] leading-[14px] transition-colors duration-300 ${
          disable
            ? 'bg-[#5F8FFE] text-[#fff]'
            : 'border-none bg-[#4170FF] text-[#FFFFFF] shadow-[0_2px_12px_0_rgba(0,0,0,0.12)] hover:bg-[#5488FF] active:bg-[#84A9FF]'
        } ${className}`}
        onClick={disable ? () => null : onClick}
        {...rest}
      >
        {showLoading ? (
          <img
            className="mr-[8px] h-[14px] w-[14px]"
            src={IconLogoLoading}
            alt=""
          />
        ) : (
          ''
        )}
        {children}
      </div>
    );

    const normalButton = () => (
      <div
        className={`flex min-w-[140px] cursor-pointer items-center justify-center rounded-[10px] border border-[#b7b7b7] bg-[#fff] px-[36px] py-[10px] text-[14px] leading-[14px] text-[#666] hover:bg-[#f9f9f9] active:bg-[#f4f4f4] ${
          disable ? 'bg-[#E8E8E8]' : 'bg-[#fff]'
        } ${className}`}
        // style={{ filter: 'drop-shadow(0px 2px 12px rgba(0,0,0,0.12))' }}
        onClick={disable ? () => null : onClick}
        {...rest}
      >
        {showLoading ? (
          <img
            className="mr-[8px] h-[14px] w-[14px]"
            src={IconLogoLoading}
            alt=""
          />
        ) : (
          ''
        )}
        {children}
      </div>
    );

    const outlineButton = () => (
      <div
        className={`flex cursor-pointer items-center justify-center rounded-[10px] bg-[linear-gradient(90deg,_#F3FFD2,_#E8FFED)] bg-clip-border bg-origin-border text-[14px] leading-[14px]  text-[#666] hover:bg-[linear-gradient(90deg,_#F3FFDD,_#bff4f4)] active:bg-[#f4f4f4]`}
        style={{ boxShadow: '0px 2px 12px 0 rgba(0,0,0,0.12)' }}
        // {...rest}
      >
        <div
          className={`m-[1px]  flex w-[100%] min-w-[140px] items-center justify-center rounded-[10px] bg-[#fff] px-[36px] py-[10px] ${
            disable ? 'bg-[#E8E8E8]' : 'bg-[#fff]'
          } ${className}`}
          onClick={disable ? () => null : onClick}
          {...rest}
        >
          {showLoading ? (
            <img
              className="mr-[8px] h-[14px] w-[14px]"
              src={IconLogoLoading}
              alt=""
            />
          ) : (
            ''
          )}
          {children}
        </div>
      </div>
    );

    const renderBtn = () => {
      switch (variant) {
        case 'normal':
          return normalButton();
        case 'primary':
          return primaryButton();
        case 'outline':
          return outlineButton();
        default:
          return primaryButton();
      }
    };

    return <>{renderBtn()}</>;
  }
);

Button.displayName = 'Button';

export default Button;
