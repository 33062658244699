import * as pkg from '../package.json';

export interface Config {
  basename: string;
  defaultPath: string;
  projectName: string;
  name: string;
  index: string;
  indexOut: string;
  createdTime: string;
  publicPath: string;
  output: string;
  cdn: {
    host: string;
    bucket: string;
    region: string;
    secretAccessKey: string;
    accessKeyId: string;
  };
}

export const projectBasePath: string =
  process.env.NODE_ENV === 'testnet'
    ? 'testnet/' + pkg.name + '/' + pkg.version + '/'
    : pkg.name + '/' + pkg.version + '/';

export const config: Config = {
  basename: '',
  defaultPath: '/home',
  projectName:
    process.env.NODE_ENV === 'testnet' ? 'testnet/' + pkg.name : pkg.name,
  name: projectBasePath,
  index: 'index.html',
  indexOut: './index.html',
  createdTime: '2022-07-26',
  publicPath: '/',
  output: '../dist',
  cdn: {
    host:
      process.env.NODE_ENV === 'prod'
        ? 'https://cdn.vitae3.me/'
        : 'https://test-cdn.vitae3.me/',
    bucket: process.env.NODE_ENV === 'prod' ? 'cdn-fe' : 'test-cdn-fe',
    region: 'us-east-1',
    secretAccessKey: '',
    accessKeyId: ''
  }
};

// sentry 配置
export const sentryConfig: {
  dsn: string;
  tracesSampleRate: number;
  release: string;
  environment: string;
} = {
  // dsn: 'https://133c2bb31ed14e58a7a373cc87f87dc1@o1432047.ingest.sentry.io/6783039',
  dsn:
    process.env.NODE_ENV === 'prod'
      ? 'https://7ef60e46d1bb4e6eb882b55d05b5063d@o4505389444235264.ingest.sentry.io/4505390922792960'
      : 'https://ada9004e328440bdad8ee79acb3ffc07@o4505389444235264.ingest.sentry.io/4505389448298496',
  release: `${process.env.NODE_ENV}-${pkg.version}`,
  tracesSampleRate: 1.0,
  environment:
    process.env.NODE_ENV === 'prod'
      ? 'production'
      : process.env.NODE_ENV === 'test'
      ? 'test'
      : process.env.NODE_ENV === 'testnet'
      ? 'testnet'
      : process.env.NODE_ENV === 'grey'
      ? 'grey'
      : 'local'
};

export default config;
