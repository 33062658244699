import { Card } from '../card';
import Tooltip from '../tooltip';
import { Books } from './books';
import { Connect } from './connect';
import { Mail } from './mail';
import { Routes } from './routes';

const NavbarMobile = () => {
  return (
    <div className="flex items-center text-[18px] leading-[32px] text-[#4E4E4E]">
      <div className="hidden justify-center sm:flex sm:flex-1">
        <Mail />
      </div>

      <div className="flex items-center justify-end">
        <Tooltip
          trigger={['click']}
          showArrow={false}
          placement="bottomLeft"
          overlayInnerStyle={{
            backgroundColor: '#fff',
            padding: 0,
            marginTop: '4px'
          }}
          overlay={
            <Card
              style={{ boxShadow: '0px 2px 10px 0 rgba(111,111,111,0.21)' }}
            >
              <div className="flex flex-col px-[20px] py-[12px]">
                <div className="mt-4 ml-4">
                  <Routes />
                </div>
                <div className="mt-6 ml-[24px]">
                  <Books />
                </div>
                {/* <div className="visible ml-4 mt-6 justify-center sm:invisible">
                  <Mail />
                </div> */}
                <div className="mt-6">
                  <Connect />
                </div>
              </div>
            </Card>
          }
        >
          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1_715)">
                <rect width="24" height="24" fill="white" />
                <path
                  d="M4.02667 6C3.45965 6 3 6.50144 3 7.12C3 7.73856 3.45965 8.24001 4.02667 8.24001H23.9733C24.5403 8.24001 25 7.73856 25 7.12C25 6.50144 24.5403 6 23.9733 6H4.02667Z"
                  fill="black"
                />
                <path
                  d="M3 14C3 13.3814 3.45965 12.88 4.02667 12.88H23.9733C24.5403 12.88 25 13.3814 25 14C25 14.6186 24.5403 15.12 23.9733 15.12H4.02667C3.45965 15.12 3 14.6186 3 14Z"
                  fill="black"
                />
                <path
                  d="M4.02667 19.76C3.45965 19.76 3 20.2614 3 20.88C3 21.4986 3.45965 22 4.02667 22H23.9733C24.5403 22 25 21.4986 25 20.88C25 20.2614 24.5403 19.76 23.9733 19.76H4.02667Z"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_715">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default NavbarMobile;
