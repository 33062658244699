// import { Books } from './books';
import { Connect } from './connect';
import { Mail } from './mail';
// import { Routes } from './routes';
import { Organization } from './organization';

const NavbarPc = () => {
  return (
    <div className="flex items-center text-[18px] leading-[32px] text-[#4E4E4E]">
      <Organization />
      {/* <Routes /> */}
      {/* <Books /> */}
      <Mail />
      <Connect />
    </div>
  );
};

export default NavbarPc;
