import React, {
  FC,
  InputHTMLAttributes,
  memo,
  ReactNode,
  useRef,
  useState
} from 'react';

import search from '@/assets/organization/search.svg';

interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  children?: ReactNode;
  className?: string;
  placeholder?: string | undefined;
  onBlur?: any;
  onFocus?: any;
  onEnter?: (value: string) => void;
}

const SearchInput: FC<SearchInputProps> = memo(
  ({
    className,
    children,
    placeholder,
    onBlur,
    onFocus,
    onEnter,
    ...rest
  }: SearchInputProps) => {
    const ref: any = useRef();
    const [isFocus, setIsFocus] = useState(false);
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && onEnter) {
        onEnter(e.currentTarget.value);
      }
    };
    return (
      <div
        className={`${
          isFocus ? 'custom-input-border-focus' : 'custom-input-border'
        } flex items-center rounded-full border border-[#A7AABA] bg-white py-[20px] px-[24px] text-left text-[14px] leading-[20px] outline-none  ${className} `}
        ref={ref}
      >
        <img className="mr-[18px]" src={search} />
        <input
          className="w-[100%] outline-none placeholder:text-[#b7b7b7]"
          placeholder={placeholder}
          {...rest}
          onBlur={(e) => {
            setIsFocus(false);
            onBlur ? onBlur(e) : null;
          }}
          onFocus={(e) => {
            setIsFocus(true);
            onFocus ? onFocus(e) : null;
          }}
          onKeyDown={handleKeyDown}
        >
          {children}
        </input>
      </div>
    );
  }
);

SearchInput.displayName = 'SearchInput';

export default SearchInput;
