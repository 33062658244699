import { FC, HTMLAttributes, memo, ReactNode } from 'react';

interface Props extends HTMLAttributes<HTMLElement> {
  src?: string;
  className?: string;
  content?: ReactNode;
  text?: ReactNode;
  badgeStyle?: string;
  showCircle?: boolean;
}

const Badge: FC<Props> = memo(
  ({ text, className, content, badgeStyle, showCircle, ...rest }: Props) => {
    return (
      <div
        className={`relative inline-flex cursor-pointer items-center rounded-lg pt-[14px] pr-[18px] text-center text-sm font-medium text-white ${className}`}
        {...rest}
      >
        {content}
        {text ? (
          <div
            className={`absolute top-[0px] right-[0px] inline-flex min-h-[18px] min-w-[18px] items-center justify-center rounded-full bg-[#345EDC] text-[12px] font-[700] text-white ${badgeStyle}`}
          >
            {text}
          </div>
        ) : null}
        {showCircle ? (
          <div
            className={`absolute top-[25px] right-[28px] inline-flex min-h-[8px] min-w-[8px] items-center justify-center rounded-full bg-[#345EDC] text-[12px] font-[700] text-white ${badgeStyle}`}
          ></div>
        ) : null}
      </div>
    );
  }
);

Badge.displayName = 'Badge';

export default Badge;
