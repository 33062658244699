import { useEffect } from 'react';
import { Link, Outlet, useParams } from 'react-router-dom';
import { Card } from '@nextui-org/react';

import { SettingsIcon } from '@/components/icons/settingsIcon';
import toast from '@/components/toast';
import Tooltip from '@/components/tooltip';
import discord from '@/assets/organization/discord.svg';
import more from '@/assets/organization/more.svg';
import network from '@/assets/organization/network.svg';
import twitter from '@/assets/organization/twitter.svg';
import { apiGetOrgList, join, leave } from '@/apis/org';
import NavBar from '@/pages/organization/model/navBar';
import { useOrganization } from '@/pages/organization/model/organizationContext';
import { useAppSetting } from '@/store/appSetting';

import './index.css';

const OrganizationDetail: React.FC = () => {
  const { organization, setOrganization } = useOrganization();
  const { auth, joinedOrg, setJoinedOrg } = useAppSetting();

  const { id } = useParams();

  // useEffect to getdata if organization is null or empty
  useEffect(() => {
    const getOrg = async () => {
      const res = await apiGetOrgList({
        isAdmin: true,
        orgIdList: id
      });
      setOrganization(res?.items[0] ?? {});
    };
    getOrg();
  }, [id]);

  const joinOrg = async (id) => {
    try {
      await join({ orgID: id });
      const res = await apiGetOrgList({ isAdmin: true, orgIdList: id });
      setJoinedOrg([res?.items[0], ...joinedOrg]);
      setOrganization(res?.items[0] ?? {});
      toast.success('Joined successfully');
    } catch (error) {
      console.error(error);
      // Display a user-friendly error message here
    }
  };

  const isNotAdmin = !organization?.adminAddressList?.includes(auth.address);

  const leaveOrg = async (id: string) => {
    try {
      await leave({ orgID: id });
      const res = await apiGetOrgList({ isAdmin: true, orgIdList: id });
      setJoinedOrg(joinedOrg.filter((org) => org.id !== id));
      setOrganization(res?.items[0] ?? {});
      toast.success('Leave successfully');
    } catch (error) {
      console.error(error);
      // Display a user-friendly error message here
    }
  };
  return (
    <>
      <div className="!mx-0 !w-full !grow !justify-center !overflow-y-scroll bg-[#F6F5FB] !pr-0 !pl-4 !pt-[22px] md:flex">
        <Card
          variant="bordered"
          className="!md:w-[172px] !md:mr-0 relative !mr-4 !mb-4 !h-fit !w-[initial] !max-w-full !p-0"
        >
          {/* {isNotAdmin && joined && ( */}
          {isNotAdmin && organization?.isJoined && (
            <Tooltip
              placement="bottom"
              trigger="click"
              overlay={
                <button
                  className={`relative flex h-[30px] w-[96px] cursor-pointer items-center justify-center rounded-[4px] bg-[#F0F0F0]  text-[14px] text-[#1D2129]`}
                  onClick={() => leaveOrg(organization?.id)}
                >
                  Leave Group
                </button>
              }
            >
              <img
                className="absolute top-4 right-5 cursor-pointer"
                src={more}
              />
            </Tooltip>
          )}

          {!isNotAdmin && (
            <div className="absolute top-[16px] right-[14px] cursor-pointer">
              <Link
                to={`/organization/${organization?.id}/settings?slug=${organization?.slug}`}
              >
                <SettingsIcon size={18} />
              </Link>
            </div>
          )}

          <div className="flex flex-col items-center justify-center pt-[43px] font-[Roboto]">
            <img
              className="h-[64px] w-[64px] rounded-full"
              src={organization?.avatar}
            />

            <div className="mt-[20px] mb-[10px] break-words px-3.5 text-center text-[22px] font-[500] text-[#1D2129]">
              {organization?.name}
            </div>
            {/* <div className="ellipsis-5 break-all px-3.5 text-center text-[14px] font-[400]">
              {organization?.desc}
            </div> */}
          </div>
          {/* ICON */}
          <div
            className={`flex items-center justify-center text-center ${
              organization?.twitter ||
              organization?.discord?.inviteLink ||
              organization?.website
                ? ''
                : 'hidden'
            }`}
          >
            <div className="flex">
              {organization?.twitter && (
                <a
                  className="flex h-6 w-6 items-center justify-center"
                  href={
                    'https://twitter.com/' +
                    organization?.twitter.split('/').pop()
                  }
                >
                  <img className="h-[18px] w-[18px]" src={twitter} alt="" />
                </a>
              )}
              {organization?.discord?.inviteLink && (
                <a
                  className="mx-[14px] flex h-6 w-6 items-center justify-center"
                  href={organization?.discord?.inviteLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="h-[18px] w-[18px]" src={discord} alt="" />
                </a>
              )}
              {organization?.website && (
                <a
                  className="flex h-6 w-6 items-center justify-center"
                  href={
                    organization?.website.includes('https')
                      ? organization?.website
                      : 'https://' + organization?.website
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="h-[18px] w-[18px]" src={network} alt="" />
                </a>
              )}
            </div>
          </div>

          <div
            className={`flex w-full items-center justify-center pt-[1px] font-[Roboto] ${
              organization?.isJoined ? 'hidden' : ''
            }`}
          >
            {!organization?.isJoined && (
              <button
                className="w-[120px] cursor-pointer rounded-[30px] bg-[#365FDC] p-[3px] text-center text-[16px] font-[500] text-[#FFFFFF]"
                onClick={() => joinOrg(organization?.id)}
              >
                Join
              </button>
            )}
          </div>
          <i className="my-0 mx-auto mt-[14px] h-[1px] w-[144px] bg-[#F0F0F0]" />
          <NavBar />
        </Card>

        <div className="!md:ml-4 !ml-0 !h-fit !min-h-screen !max-w-full !flex-1 !bg-[#f6f5fa] !pr-4 !pb-[50px] md:ml-0">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default OrganizationDetail;
