import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import plus from '@/assets/organization/plus.svg';
import { apiGetOrgList } from '@/apis/org';
import { useOrganization } from '@/pages/organization/model/organizationContext';
import { useAppSetting } from '@/store/appSetting';

const SideBar = () => {
  const { joinedOrg } = useAppSetting();
  const { setOrganization } = useOrganization();
  const { id } = useParams();
  const navigate = useNavigate();

  const handleClick = async () => {
    if (localStorage.getItem('isInWhiteList') === 'true') {
      navigate('/organization/new?type=new');
    } else {
      window.open('https://tally.so/r/3xXz4o');
    }
  };
  const fetchActiveOrgDetail = async () => {
    const org = await apiGetOrgList({ orgIdList: id, isAdmin: true });
    if (org?.items.lenth > 0) {
      setOrganization(org.items[0]);
    }
  };
  useEffect(() => {
    fetchActiveOrgDetail();
  }, [id]);
  return (
    <div className="fixed top-[64px] bottom-0 w-[60px] overflow-y-scroll border-r-[1px] bg-white">
      <div className="flex flex-col items-center">
        {joinedOrg?.map((item, index) => (
          <Link
            to={`/organization/${item.id}`}
            key={index}
            className="relative left-0 mt-[16px] h-[38px] w-full"
          >
            {id === item.id && (
              <i className="absolute inset-y-0 left-0 my-auto block h-[20px] w-[4px] rounded-r-full bg-[#345EDC]"></i>
            )}
            <img
              className="mx-[11px] h-[36px] w-[36px] cursor-pointer rounded-full border border-[#A7AABA] text-[#1D2129]"
              src={item.avatar}
            />
          </Link>
        ))}
        <button
          className="mt-[16px] flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-full border border-[#DFDBDB]"
          onClick={() => handleClick()}
        >
          <img
            className="mx-[11px] h-[30px] w-[30px] text-[#1D2129]"
            src={plus}
          />
        </button>
      </div>
    </div>
  );
};

export default SideBar;
